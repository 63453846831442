@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-add: "\EA01"
$icon-add2: "\EA02"
$icon-arrow-left: "\EA03"
$icon-arrow-right: "\EA04"
$icon-burger: "\EA05"
$icon-cart: "\EA06"
$icon-close: "\EA07"
$icon-compare: "\EA08"
$icon-date: "\EA09"
$icon-plus: "\EA10"
$icon-remove: "\EA11"
$icon-reset: "\EA12"
$icon-search: "\EA13"
$icon-settings: "\EA14"
$icon-toggle: "\EA15"
$icon-trash: "\EA16"
$icon-view: "\EA17"
$icon-view1: "\EA18"
$icon-chevron-right: "\EA19"
$icon-dropdown: "\EA20"
$icon-eyes1: "\EA21"
$icon-eyes2: "\EA22"
$icon-minus: "\EA23"
$icon-pdf: "\EA24"
$icon-phone: "\EA25"
$icon-view2: "\EA26"
$icon-checkmark: "\EA27"
$icon-chevron-left: "\EA28"
$icon-file: "\EA29"
$icon-play: "\EA30"
$icon-orders: "\EA31"
$icon-edit: "\EA32"
$icon-save: "\EA33"
$icon-message: "\EA34"
$icon-bookmark: "\EA35"
$icon-user: "\EA36"
$icon-document: "\EA37"
$icon-gear: "\EA38"
$icon-users: "\EA39"
$icon-list: "\EA40"
$icon-clear-filter: "\EA41"
$icon-photo: "\EA42"
$icon-login: "\EA43"
$icon-plus2: "\EA44"
$icon-circle-close: "\EA45"
$icon-help-circle: "\EA46"
$icon-circle-info: "\EA47"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-add:before
	content: $icon-add
.icon-add2:before
	content: $icon-add2
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-arrow-right:before
	content: $icon-arrow-right
.icon-burger:before
	content: $icon-burger
.icon-cart:before
	content: $icon-cart
.icon-close:before
	content: $icon-close
.icon-compare:before
	content: $icon-compare
.icon-date:before
	content: $icon-date
.icon-plus:before
	content: $icon-plus
.icon-remove:before
	content: $icon-remove
.icon-reset:before
	content: $icon-reset
.icon-search:before
	content: $icon-search
.icon-settings:before
	content: $icon-settings
.icon-toggle:before
	content: $icon-toggle
.icon-trash:before
	content: $icon-trash
.icon-view:before
	content: $icon-view
.icon-view1:before
	content: $icon-view1
.icon-chevron-right:before
	content: $icon-chevron-right
.icon-dropdown:before
	content: $icon-dropdown
.icon-eyes1:before
	content: $icon-eyes1
.icon-eyes2:before
	content: $icon-eyes2
.icon-minus:before
	content: $icon-minus
.icon-pdf:before
	content: $icon-pdf
.icon-phone:before
	content: $icon-phone
.icon-view2:before
	content: $icon-view2
.icon-checkmark:before
	content: $icon-checkmark
.icon-chevron-left:before
	content: $icon-chevron-left
.icon-file:before
	content: $icon-file
.icon-play:before
	content: $icon-play
.icon-orders:before
	content: $icon-orders
.icon-edit:before
	content: $icon-edit
.icon-save:before
	content: $icon-save
.icon-message:before
	content: $icon-message
.icon-bookmark:before
	content: $icon-bookmark
.icon-user:before
	content: $icon-user
.icon-document:before
	content: $icon-document
.icon-gear:before
	content: $icon-gear
.icon-users:before
	content: $icon-users
.icon-list:before
	content: $icon-list
.icon-clear-filter:before
	content: $icon-clear-filter
.icon-photo:before
	content: $icon-photo
.icon-login:before
	content: $icon-login
.icon-plus2:before
	content: $icon-plus2
.icon-circle-close:before
	content: $icon-circle-close
.icon-help-circle:before
	content: $icon-help-circle
.icon-circle-info:before
	content: $icon-circle-info
